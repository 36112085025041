import { Question } from './question.model';

export interface Pro {
  _id: string;
  name: string;
  steps: ProStep[];
}

export interface ProStep {
  skip: boolean;
  proId?: string;
  questionId?: string;
  orderIndex: number;
  showInProgress: boolean;
  rules: Rule[];
  routerRules: Record<string, Rule[]>;
  question?: Question;
  conditions: any[];
}

export interface Rule {
  operatorSymbol: string;
  operatorValue: string;
  operatorValue2: string;
  index?: number;
  type: string;
  value: any;
  values?: any;
  factors?: RuleFactor[];
  factorSet: Record<string, RuleFactor>;
  validationResult: any;
  initial: any;
  initialChecked: boolean;

  target: any;
  targetChecked: boolean;
  override: any;
  applicable?: boolean;
}

export interface RuleFactor {
  value?: any;
  operatorValue?: any;
  operatorValue2?: any;
  operatorSymbol: string;
  initialMin: number;
  initialMax: number;
  currentMin: number;
  currentMax: number;
  changeMin: number;
  changeMax: number;
  valid: boolean;
}

export enum RuleOperators {
  eq = 'eq',
  neq = 'neq',
  lt = 'lt',
  lte = 'lte',
  gt = 'gt',
  gte = 'gte',
  bt = 'bt',
  bte = 'bte',
  like = 'like',
  nlike = 'nlike',
}
