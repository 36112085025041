import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SessionKeys } from '@services/consts';
import { KeyPressDistributionService } from '@services/keypress-distribution.service';
import { LoaderService } from '@services/loader.service';
import { LocalStorageService } from '@services/localStorage.service';
import { ProRouterService } from '@services/pro-router.service';
import { ResultsService } from '@services/results.service';
import { SessionStorageService } from '@services/sessionStorage.service';
import { TokenService } from '@services/token.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrl: './login-dialog.component.scss',
})
export class LoginDialogComponent {
  pendingValidation = false;
  _proResultsParams?: { t: string; p: string; e: string };
  @Input() set proResultsParams(value: any) {
    if (value?.['t'] && value?.['p']) {
      this._proResultsParams = value;
      this.loginPayload = { tempLoginToken: value['t'] };
      this.offerValidationDialog();
    }
  }
  @ViewChild('inputData', { static: true }) inputData: any;
  @ViewChild('tokenEmailValidationDialog', { static: true }) modalTemplate!: TemplateRef<any>;
  modalRef?: BsModalRef;
  config = {
    class: 'modal-dialog-email-validator',
    animated: true,
    backdrop: true,
    ignoreBackdropClick: true,
  };
  loginPayload: any;
  userEmail: string = '';

  @Output() pendingValidationState: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    public tokenService: TokenService,
    public keyService: KeyPressDistributionService,
    public router: Router,
    public route: ActivatedRoute,
    public loaderService: LoaderService,
    public resultsService: ResultsService,
    public proRouterService: ProRouterService,
    public sessionStorageService: SessionStorageService,
    public localStorageService: LocalStorageService,
    private modalService: BsModalService,
  ) {}

  clearDataForReset($event: Event) {
    const loadResults = window.location.href.includes('dir=');
    if (!loadResults) {
      this.sessionStorageService.removeItem(SessionKeys.Timeline);
      this.sessionStorageService.removeItem(SessionKeys.DimensionResults);
      this.sessionStorageService.removeItem(SessionKeys.Results);

      this.sessionStorageService.removeItem(SessionKeys.Mongo);
      this.sessionStorageService.removeItem(SessionKeys.Elastic);

      this.pendingValidation = false;
      this.closeDialog();

      // move to first stage
      if (window.location.href.includes('/pro/')) {
        const url = window.location.pathname.split('/');
        url.pop();
        url.push('0');
        this.router.navigate(url);
      }
    }
  }

  offerValidationDialog() {
    this.pendingValidation = true;

    this.pendingValidationState.emit(this.pendingValidation);
    this.modalRef = this.modalService.show(this.modalTemplate, this.config);
  }

  resetEmailValidation($event: Event) {
    $event.preventDefault();
    this.emailErrorIcon = false;
  }
  emailError = '';
  emailErrorIcon = false;
  emailValid = false;
  proExpiredMode = false;
  validatingEmailLoader = false;
  async vaidateEmailAndContinue($event: Event) {
    $event.preventDefault();
    if (!this.userEmail) {
      return false;
    }

    this.validatingEmailLoader = true;
    this.loginPayload['email'] = this.userEmail;
    const loginResponse = await this.tokenService.loginOnly(this.loginPayload);
    this.validatingEmailLoader = false;
    if (loginResponse['error']) {
      this.emailError = loginResponse['error'];
      this.emailErrorIcon = true;
    } else {
      this.emailValid = true;

      //store email
      this.resultsService.setResultsForDimension('email', loginResponse.email);
      if (this._proResultsParams?.['p']) {
        const proIsValid = await this.proRouterService.isProValidToContinue(this._proResultsParams?.['p']);

        if (!proIsValid.proDate) {
          this.proExpiredMode = true;
          this.completionNotice = 'Your assessment has expired. Please start over.';
        }

        if (!proIsValid.proResumeValid) {
          this.completionNotice = 'Your assessment has been completed. Please start over.';
        }
      }
    }
    return;
  }

  completionNotice = '';

  resumePro($event: Event) {
    $event.preventDefault();
    this.pendingValidation = false;
    this.closeDialog();
  }

  closeDialog() {
    this.pendingValidationState.emit(this.pendingValidation);
    this.modalService.hide();
  }

  resetProAndContinue($event: Event) {
    $event.preventDefault();
    this.pendingValidation = false;
    this.sessionStorageService.removeItem(SessionKeys.Timeline);
    this.sessionStorageService.removeItem(SessionKeys.Mongo);
    this.sessionStorageService.removeItem(SessionKeys.Elastic);

    this.closeDialog();
    const url = window.location.pathname.split('/');
    url.pop();
    url.push('0');
    this.router.navigate(url);
  }
}
