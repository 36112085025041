import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

export type ProRouterResponse = { matcher: Record<string, any>; condition: { _id: string; proTarget: string } };

@Injectable()
export class ProRouterService {
  public serviceUrl = '/api/pro-router';

  constructor(private http: HttpClient) {}

  public async resolveUserRouter(): Promise<ProRouterResponse> {
    return firstValueFrom(this.http.get<ProRouterResponse>(`${this.serviceUrl}/resolve`));
  }

  public async resolveProConditions(conditionsDic: Record<string, any>, skipOrderVerification: boolean = true): Promise<Record<string, any>> {
    return firstValueFrom(
      this.http.get<Record<string, any>>(`${this.serviceUrl}/resolve-pro-conditions/?verify=${skipOrderVerification}&conditions=${Object.keys(conditionsDic).join(',')}`),
    );
  }

  public async resolveFlagConditions(conditions: string[]): Promise<Record<string, string>> {
    return firstValueFrom(this.http.get<Record<string, string>>(`${this.serviceUrl}/resolve-flag-conditions/?conditions=${conditions.join(',')}`));
  }

  public async getAllConditions(): Promise<Record<string, string>> {
    return firstValueFrom(this.http.get<Record<string, string>>(`${this.serviceUrl}/conditions/`));
  }

  public async customerLastOrder(): Promise<string[]> {
    return firstValueFrom(this.http.get<string[]>(`${this.serviceUrl}/last-order/`));
  }

  public async isProValidToContinue(mongoId: string): Promise<any> {
    return firstValueFrom(this.http.get<string[]>(`${this.serviceUrl}/pro-date-valid/${mongoId}`));
  }
}
