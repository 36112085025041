/* eslint-disable @typescript-eslint/no-unused-vars */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, NgZone, OnChanges, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KeyPressDistributionService } from '@services/keypress-distribution.service';
import { PagingService } from '@services/paging.service';
import { PropertiesBaseComponent } from '@base/properties-base.component';
import { DirtyService } from '@services/dirty.service';
import { ResultsService } from '@services/results.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LoaderService } from '@services/loader.service';
import { environment } from '@environments/environment';
import { SessionStorageService } from '@services/sessionStorage.service';

const EVINATURE_EXCEPTIONS = 'EVINATURE_EXCEPTIONS';
@Component({
  selector: 'app-select-meds',
  templateUrl: './select-meds.component.html',
  styleUrls: ['./select-meds.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class SelectMedsComponent extends PropertiesBaseComponent implements OnChanges {
  modalRef?: BsModalRef;

  @ViewChild('template') modalTemplate?: TemplateRef<any>;
  @ViewChild('dosageModalTemplate') dosageModalTemplate?: TemplateRef<any>;

  constructor(
    public _ngZone: NgZone,
    public changeDetectorRef: ChangeDetectorRef,
    public router: Router,
    public route: ActivatedRoute,
    public dirtyService: DirtyService,
    public resultsService: ResultsService,
    public keyServiceRef: KeyPressDistributionService,
    public pagingService: PagingService,
    private modalService: BsModalService,
    public gtmService: GoogleTagManagerService,
    public loaderService: LoaderService,
    public sessionStorageService: SessionStorageService,
  ) {
    super(_ngZone, changeDetectorRef, router, route, dirtyService, pagingService);
    this.canContinue = false;
    this.subscriptions.add(
      this.onLoad.subscribe(async (question): Promise<void> => {
        this._properties.subDimensions.forEach((subDomain: any) => {
          subDomain.name = subDomain.name.toLowerCase();
        });

        this._properties.text1 = this._properties.text1 || 'Continue';
        if (this._properties.sourceDimension) {
          const priorData = await this.resultsService.getResultsForDimension(this._properties.sourceDimension);
          this.optionsArray = priorData;
        } else {
          this.optionsArray = this._properties.options;
        }
        const enteredValue = this.resultsService.getResultsForQuestion(question._id);

        if (enteredValue && Array.isArray(enteredValue)) {
          this.pagingService.configure({ next: true, prev: true });

          enteredValue.forEach(item => {
            this._properties.subDimensions.forEach((subDomain: any) => {
              if (item[subDomain.name]) {
                this.optionsArray[item.index][subDomain.name] = true;
              }
            });
          });
        }

        this.loaderService.isReady();
      }),
    );

    const subscription = this.keyServiceRef.keyEventObs.subscribe(keys => {
      switch (keys) {
        case 'k-ArrowDown':
          if (this.selectedIndex < this.optionsArray.length - 1) {
            this.selectedIndex++;
            this.clearActive();
            (this.optionsArray[this.selectedIndex] as any).isActive = true;
          }
          break;
        case 'k-ArrowUp':
          if (this.selectedIndex > 0) {
            this.selectedIndex--;
          }
          this.clearActive();
          (this.optionsArray[this.selectedIndex] as any).isActive = true;
          break;
        case 'k-Space':
          (this.optionsArray[this.selectedIndex] as any).value = true;
          this.updateProps();
          break;
        case 'k-Enter':
          if (this.updateProps()) {
            this.resultsService.nextPage();
          }
          break;
      }
    });

    this.subscriptions.add(subscription);
  }

  public selectedIndex = -1;

  public values: any = {};

  notValid = false;

  closeBtnVisible = true;

  activeTextBubble: { countdown: 0; prefix: string; info: string } | null = null;

  public optionsArray: any[] = [];

  public usedQuestions = {};

  public rulesDictionary = {};

  public clearActive() {
    this.optionsArray.forEach((option: any) => {
      delete option.isActive;
    });
  }

  public ngOnChanges(changes: SimpleChanges) {}

  activeDosage = '';
  dosageOptions = [];
  updateDosage() {
    this.activeOption.dosageApplied = this.activeDosage;
    this.modalRef?.hide();
    this.activeOption = {};
    this.activeDosage = '';
  }

  activeOption: any = {};
  public showModalForOption(index: number, optionCheck: Record<string, boolean>, dimKeyVar: string) {
    const dimKey = dimKeyVar.toLocaleLowerCase();

    console.info('Log: option clicked', optionCheck[dimKeyVar], dimKey);
    if (this._properties.subDimensions.length > 1 && dimKey !== 'current') {
      if (optionCheck[dimKey]) {
        this.handleOptionClick(index, optionCheck, dimKey);
      }
    } else {
      // handle current and single dimension cases

      console.info('Log: in current ', optionCheck, dimKey);
      if (optionCheck[dimKey]) {
        this.handleOptionClick(index, optionCheck, dimKey);
        this.activeTextBubble = this._properties.options[index];
        if (this.activeTextBubble?.info) {
          this.openModal();
        }

        const dosage = this._properties.options[index].dosage;
        if (dosage) {
          this.activeOption = optionCheck;
          // build options
          this.dosageOptions = dosage.split('\n');
          if (this.dosageOptions) {
            this.openDosageModal();
          }
        }
      } else {
        console.info('Log: select meds option click');
        // disable the button if none selected

        if (optionCheck.dosageApplied) {
          delete optionCheck.dosageApplied;
        }

        let valid = false;
        for (const dimension of this._properties.subDimensions) {
          for (const option of this.optionsArray) {
            const dimensionName = dimension.name.toLowerCase();
            console.info('Log: test', option[dimensionName]);
            if (option[dimensionName]) {
              console.info('Log: dimension tested and found', option[dimensionName]);
              valid = true;
              break;
            }
          }
        }
        console.info('Log: set next to', valid);
        this.pagingService.configure({ next: valid, prev: true });
      }
    }
  }

  handleOptionClick(i: number, optionCheck: Record<string, boolean>, dimKey: string) {
    if (optionCheck.exclusive) {
      this.optionsArray.forEach((option, index) => {
        if (i !== index) {
          option[dimKey] = null;
        }
      });
    } else {
      this.optionsArray.forEach((option, index) => {
        if (this._properties.options[index].exclusive) {
          option[dimKey] = null;
        }
      });
    }
    this.pagingService.configure({ next: true, prev: true });
  }

  config = {
    class: 'modal-dialog',
    animated: false,
  };

  openModal() {
    this.modalRef = this.modalService.show(this.modalTemplate!, this.config);
  }

  openDosageModal() {
    this.modalRef = this.modalService.show(this.dosageModalTemplate!, this.config);
  }
  closeDialog() {
    this.modalRef?.hide();
  }

  setActiveOptionHighlight(index: number) {
    this.optionsArray.forEach(item => {
      delete item.isActive;
    });
    this.optionsArray[index].isActive = true;
  }

  moveNext() {
    if (this.updateProps()) {
      if (this.question?.ga_event && environment.production) {
        this.gtmService.pushTag({ event: `${this.question.ga_event}_clicked` });
      }
      this.resultsService.nextPage();
    }
  }

  storeExceptions(exceptionsToMerge: any, questionId: string) {
    if (exceptionsToMerge?.length) {
      const exceptionsObject = this.sessionStorageService.getItem<any>(EVINATURE_EXCEPTIONS) || {};
      exceptionsObject[questionId] = exceptionsToMerge;
      this.sessionStorageService.setItem(EVINATURE_EXCEPTIONS, exceptionsObject);
    }
  }

  public updateProps() {
    const retValues: any[] = [];
    let exceptions: any[] = [];
    this.optionsArray.forEach((option: any, index: number) => {
      const rowObject: Record<string, any> = { index, label: option.label, dosage: option.dosageApplied, exceptions: option.exceptions };
      this._properties.subDimensions.forEach((subDimension: { name: string; title: string }) => {
        subDimension.name = subDimension.name.toLowerCase();
        const dimensionName = subDimension.name.toLowerCase();

        if (option[dimensionName]) {
          if (option.exceptions && dimensionName !== 'past') {
            const matchedExceptions = option.exceptions.slice().map((o: any) => ({ ...o, source: option.label }));
            exceptions = [...exceptions, ...matchedExceptions];
          }

          if (dimensionName) {
            rowObject[dimensionName] = option[dimensionName];
          }
          rowObject.active = true;
        }
      });
      if (rowObject.active) {
        retValues.push(rowObject);
      }
    });

    if (this.question?._id) {
      this.storeExceptions(exceptions, this.question._id);
    }

    this.onUpdate.next({
      questionId: this.question?._id,
      result: retValues,
    });
    this.notValid = false;
    return true;
  }
}
