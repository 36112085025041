import { NgModule } from '@angular/core';
import { SelectValueSliderComponent } from '../slides/pro-step/properties/select-value-slider/select-value-slider.component';
import { SelectManyComponent } from '../slides/pro-step/properties/select-many/select-many.component';
import { BMICalcComponent } from '../slides/pro-step/properties/bmi-calc/bmi-calc.component';
import { TextContinueComponent } from '../slides/pro-step/properties/text-continue/text-continue.component';
import { AgeRelateComponent } from '../slides/pro-step/properties/age-relate/age-relate.component';
import { EnterValueComponent } from '../slides/pro-step/properties/enter-value/enter-value.component';
import { SelectOneComponent } from '../slides/pro-step/properties/select-one/select-one.component';
import { TextConditionalComponent } from '../slides/pro-step/properties/text-conditional/text-conditional.component';
import { ProductCalcComponent } from '../slides/pro-step/properties/product-calc/product-calc.component';
import { FactorCalcComponent } from '../slides/pro-step/properties/factor-calc/factor-calc.component';
import { NewsletterComponent } from '../slides/pro-step/properties/newsletter/newsletter.component';
import { SelectMedsComponent } from '../slides/pro-step/properties/select-meds/select-meds.component';
import { TreatmentCheckupComponent } from '../slides/pro-step/properties/treatment-checkup/treatment-checkup.component';
import { ProRouterComponent } from '../slides/pro-step/properties/pro-router/pro-router.component';
import { ComponentsModule } from '@components/components.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { MomentModule } from 'ngx-moment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from '../shared.module';
import { QuestionPropertiesComponent } from './pro-step/properties/question-properties.component';
import { ProStepFormComponent } from '@components/pro-step-form/pro-step-form.component';
import { ngComponentOutletNew } from './pro-step/properties/component.loader.directive';
import { SelectVarComponent } from './pro-step/properties/select-var/select-var.component';

@NgModule({
  declarations: [
    ProStepFormComponent,
    QuestionPropertiesComponent,
    SelectValueSliderComponent,
    SelectOneComponent,
    EnterValueComponent,
    AgeRelateComponent,
    TextContinueComponent,
    BMICalcComponent,
    SelectManyComponent,
    FactorCalcComponent,
    ProductCalcComponent,
    TextConditionalComponent,
    NewsletterComponent,
    SelectMedsComponent,
    TreatmentCheckupComponent,
    ProRouterComponent,
    SelectVarComponent,
    ngComponentOutletNew,
  ],
  imports: [SharedModule, ComponentsModule, FormsModule, ReactiveFormsModule, RouterModule, CommonModule, HttpClientModule, BrowserModule, MomentModule, BrowserAnimationsModule],
})
export class SlidesModule {}
