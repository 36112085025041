<div *ngIf="optionsArray" class="panel centered" #container>
  <div class="columns-{{ _properties.columnType }}">
    <div class="row">
      <div *ngFor="let option of optionsArray; let i = index" class="col-{{ 12 / _properties.columnType }} form-check">
        <label class="d-flex form-check-label" [for]="optionsArray[i].label" [ngClass]="{ active: optionsArray[i] && optionsArray[i].isActive }">
          <input
            class="form-check-input check-lg"
            type="checkbox"
            name="Value"
            [disabled]="optionsArray[i].disabled"
            [title]="optionsArray[i].label"
            [checked]="optionsArray[i].value"
            
            [id]="optionsArray[i].label"
            (click)="handleOptionClick(i)"
            (change)="updateProps()"
            [ngClass]="{ hideme: _properties.layoutType !== 'text' }"
          />

          <span *ngIf="_properties.layoutType === 'text'">
            <!-- <label class="form-check-label text-lg" [for]="optionsArray[i].label"> -->
            {{ optionsArray[i].label }}
             
            <!-- </label> -->
          </span>

          <span *ngIf="_properties.layoutType === 'images'">
            <label class="form-check-label text-lg" [for]="optionsArray[i].label">
              <img
                [src]="optionsArray[i].image_url"
                [title]="optionsArray[i].label"
                [alt]="optionsArray[i].label"
                [ngClass]="{ selected: optionsArray[i].value, unselected: !optionsArray[i].value }"
              />
            </label>
          </span>

          <span *ngIf="_properties.layoutType === 'text + images'">
            <label class="form-check-label text-lg" [for]="optionsArray[i].label">
              {{ optionsArray[i].label }}<br />
              <img [src]="optionsArray[i].image_url" [alt]="optionsArray[i].label" />
            </label>
          </span>
        </label>
      </div>
    </div>
    <div *ngIf="notValid" class="nice-error"><em class="icon-exclamation-circle"></em> Choose one please?</div>
  </div>
  <div class="center">
    <button [disabled]="!canContinue" id="actionButton" class="btn btn-primary btn-lg" (click)="moveNext()" [innerHTML]="_properties.text1"></button>
  </div>
</div>
