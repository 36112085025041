import { ChangeDetectorRef, Component, EventEmitter, Input, NgZone, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Rule } from '@models/pro.model';
import { DirtyService } from '@services/dirty.service';
import { QuestionType } from '@models/question-type.model';
import { Question } from '@models/question.model';
import { LoaderService } from '@services/loader.service';
import { AgeRelateComponent } from './age-relate/age-relate.component';
import { BMICalcComponent } from './bmi-calc/bmi-calc.component';
import { EnterValueComponent } from './enter-value/enter-value.component';
import { FactorCalcComponent } from './factor-calc/factor-calc.component';
import { NewsletterComponent } from './newsletter/newsletter.component';
import { ProRouterComponent } from './pro-router/pro-router.component';
import { ProductCalcComponent } from './product-calc/product-calc.component';
import { SelectManyComponent } from './select-many/select-many.component';
import { SelectMedsComponent } from './select-meds/select-meds.component';
import { SelectOneComponent } from './select-one/select-one.component';
import { SelectValueSliderComponent } from './select-value-slider/select-value-slider.component';
import { TextConditionalComponent } from './text-conditional/text-conditional.component';
import { TreatmentCheckupComponent } from './treatment-checkup/treatment-checkup.component';
import { TextContinueComponent } from './text-continue/text-continue.component';
import { SelectVarComponent } from './select-var/select-var.component';

@Component({
  selector: 'app-question-properties',
  templateUrl: './question-properties.component.html',
  styleUrls: ['./question-properties.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: QuestionPropertiesComponent,
    },
  ],
})
export class QuestionPropertiesComponent {
  constructor(
    public _ngZone: NgZone,
    public changeDetectorRef: ChangeDetectorRef,
    public loaderService: LoaderService,
    public dirtyService: DirtyService,
  ) {
    this.dirtyService.setDirty();
    // this.loaderService.loading.emit(false);
  }

  slides: any = {
    // AgeRelateComponent: AgeRelateComponent,
    // BMICalcComponent: BMICalcComponent,
    'app-enter-value': EnterValueComponent,
    'app-factor-calc': FactorCalcComponent,
    'app-newsletter': NewsletterComponent,
    'app-pro-router': ProRouterComponent,
    'app-product-calc': ProductCalcComponent,
    'app-select-many': SelectManyComponent,
    'app-select-meds': SelectMedsComponent,
    'app-select-one': SelectOneComponent,
    'app-value-slider': SelectValueSliderComponent,
    'app-text-conditional': TextConditionalComponent,
    'app-treatment-checkup': TreatmentCheckupComponent,
    'app-text-continue': TextContinueComponent,
    'app-select-var': SelectVarComponent,
  };

  @Input('question') set question(value: Question) {
    this._question = value;
  }

  get question() {
    return this._question;
  }

  public _question: Question = {} as any;

  public properties: Record<string, any> = {};

  @Input('type') public type?: QuestionType;

  @Input('rules') public rules: Rule[] = [];

  @Output('valid') public valid: EventEmitter<any> = new EventEmitter<any>();

  @Output('paging') public paging: EventEmitter<any> = new EventEmitter<any>();

  public writeValue(properties: Record<string, any>): void {
    this.properties = properties;
  }

  public moveNext() {
    this.paging.emit(true);
  }

  public updateProps(props: Record<string, any>) {
    this.valid.emit(props);
    return true;
  }

  outputs: any = {
    onUpdate: (props: Record<string, any>) => {
      this.updateProps(props);
    },
    // paging: () => this.moveNext(),
  };
}
