import { AfterViewChecked, ChangeDetectorRef, Component, NgZone, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { ItemBaseComponent } from '@base/item-base.component';
import { ProStep } from '@models/pro.model';
import { ProService } from '@services/pro.service';
import { DirtyService } from '@services/dirty.service';
import { ResultsService } from '@services/results.service';
import { NavigationRequestModel, NavigationService } from '@services/navigation.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from '@environments/environment';
import { TimelineService } from '@services/timeline/timeline.service';
import { SessionKeys } from '@services/consts';
import { Subscription } from 'rxjs';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { TokenService } from '@services/token.service';
import { ConditionService } from '@services/condition.service';
import { ProRouterService } from '@services/pro-router.service';
import { slideInAnimation } from '../../animations';
import { LoaderService } from '@services/loader.service';
import { SessionStorageService } from '@services/sessionStorage.service';
@Component({
  selector: 'app-pro-step',
  templateUrl: './pro-step.component.html',
  styleUrls: ['./pro-step.component.scss'],
  animations: [slideInAnimation],
  host: { '[@routeAnimations]': 'next' },
})
export class ProStepComponent extends ItemBaseComponent implements AfterViewChecked, OnDestroy {
  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public _ngZone: NgZone,
    public changeDetectorRef: ChangeDetectorRef,
    private proService: ProService,
    private tokenService: TokenService,
    public dirtyService: DirtyService,
    public resultsService: ResultsService,
    public navigationService: NavigationService,
    public sanitizer: DomSanitizer,
    public timelineService: TimelineService,
    public gtmService: GoogleTagManagerService,
    public conditionService: ConditionService,
    public proRouterService: ProRouterService,
    public loaderService: LoaderService,
    private changeRef: ChangeDetectorRef,
    public sessionStorageService: SessionStorageService,
  ) {
    super(_ngZone, dirtyService, changeDetectorRef, router, route);
    this.DataController = this.proService;
    this.subscriptions.add(
      this.route.params.subscribe(async params => {
        this.loaderService.notReady();
        this.loaderService.hideContent();
        this.proId = params.id;
        try {
          await this.proService.load(this.proId, params.step_id);
        } catch (error) {
          console.info('Log: authorization retry');
          console.error(error);

          const shop = this.route.snapshot.queryParams.shop;

          await this.tokenService.create({ shop });
          await this.proService.load(this.proId, params.step_id);
          console.info('Log: loaded PRo after retry');
        }

        if (this.route.snapshot.queryParams.layout) {
          localStorage.setItem('evinature_cart', this.route.snapshot.queryParams.layout);
        }

        if (this.route.snapshot.queryParams.ref) {
          localStorage.setItem(SessionKeys.ProRef, this.route.snapshot.queryParams.ref);
          const userRef = localStorage.getItem(SessionKeys.ProRef);
          if (userRef) {
            const loginPayload: any = { userRef };
            await this.tokenService.patchToken(this.proId, loginPayload);
          }
        }

        if (this.route.snapshot.queryParams.t && !this.route.snapshot.queryParams.p) {
          const loginPayload: any = { tempLoginToken: this.route.snapshot.queryParams.t };
          const userRef = localStorage.getItem(SessionKeys.ProRef);
          if (userRef) {
            loginPayload.userRef = userRef;
          }

          await this.tokenService.login(this.proId, loginPayload);
        }

        this.data = this.proService.activePro;

        console.info('Log: PRO data is', this.data);

        this.debugMode = this.route.snapshot.queryParams.debug;

        if (params.step_id !== null && params.step_id !== undefined) {
          this.activeStepIndex = Number(params.step_id);
          if (this.activeStepIndex === 0) {
            this.sessionStorageService.removeItem(SessionKeys.Products);
            this.sessionStorageService.removeItem(SessionKeys.Factors);
            this.sessionStorageService.removeItem(SessionKeys.Timeline);
          }
          this.activeStep = this.proService.getStep(this.activeStepIndex);

          if (this.route.snapshot.queryParams.p && this.route.snapshot.queryParams.e) {
            this.sessionStorageService.setItem(SessionKeys.Mongo, this.route.snapshot.queryParams.p);
            this.sessionStorageService.setItem(SessionKeys.Elastic, this.route.snapshot.queryParams.e);
          }

          this.resultsService.activeData = null;

          if (this.activeStep?.question?.ga_event && environment.production) {
            this.gtmService.pushTag({ event: this.activeStep?.question?.ga_event });
          }

          if (this.activeStep?.question?.kalviyoEventName && this.activeStep?.question?.kalviyoPreEvents === 'pre') {
            await this.proService.publishClientEvent(this.activeStep.question.kalviyoEventName, {});
          }

          this.proService.canSkipValue = this.checkForProducts();
          let hideAfter = false;

          if (!this.conditionService.conditionsChecked) {
            const conditionsDic: Record<string, string> = {};
            this.proService.activePro?.steps.forEach(step => {
              step.conditions?.forEach(condition => {
                conditionsDic[condition] = condition;
              });
            });

            let email = this.resultsService.getResultsForDimension('email');
            if (!email) {
              const userData = this.tokenService.decodeToken();
              email = userData.email;

              hideAfter = true;
            }

            if (email && Object.keys(conditionsDic).length) {
              this.loaderService.hideContent();
              console.info('Log: got the email, check the router results', this.activeStep?.question.properties.skipOrderVerification);
              const proResolution = await this.proRouterService.resolveProConditions(conditionsDic, !this.activeStep?.question.properties.skipOrderVerification);
              this.conditionService.setConditions(proResolution);
              this.conditionService.conditionsChecked = true;
              console.info('Log: go for conditions', proResolution);
            }
          }

          if (!this.conditionService.conditionsDic || !Object.values(this.conditionService.conditionsDic).length) {
            const allConditions = await this.proRouterService.getAllConditions();
            this.conditionService.setAllConditions(allConditions);
          }

          this.timelineService.addStep(Number(this.activeStepIndex), { index: Number(this.activeStepIndex), skipOnBack: true, ops: { products: [], factors: [] } });
          let conditionsForStep: any;
          if (this.activeStep?.conditions?.length) {
            this.loaderService.notReady();

            conditionsForStep = await this.proService.shouldIstayOrShouldIskip(this.activeStepIndex);
            console.info('Log: ', conditionsForStep);
          }

          if (conditionsForStep?.currentIndex && conditionsForStep.currentIndex !== this.activeStepIndex) {
            console.info('Log: ', this.activeStepIndex, conditionsForStep?.currentIndex);
            this.navigationService.moveToStep((conditionsForStep as any)?.currentIndex);
          } else {
            if (this.activeStep?.skip && !this.route.snapshot.queryParams.enter) {
              this.activeStep = await this.proService.getNextUnSkippedStep(this.activeStepIndex);
              console.info('Log:', 'moving to getNextUnSkippedStep', this.activeStep.currentIndex);
              this.navigationService.moveToStep(this.activeStep?.currentIndex);
            } else {
              console.info('Log: natural step', params);
              this.proService.onStepChanged$.next(params as any);
            }

            if (!hideAfter) {
              this.loaderService.isReady();
            }
          }

          this.data.progress.forEach((group: any) => {
            if (this.activeStep?.questionId === group.steps[group.steps.length - 1].questionId) {
              this.activeStep.final = true;
            }
          });
        } else if (params.question_id) {
          const foundItem = this.proService.getByQuestionId(params.question_id);
          if (foundItem) {
            this.activeStepIndex = foundItem.stepIndex;
            this.activeStep = foundItem.foundStep;
          }
        }
        this.dirty = true;
        this.editUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          `${environment.adminUrl}/dashboard/questions/pro-list/${this.proId}/edit-question/${this.activeStep?.questionId}/`,
        );
      }),
    );
    this.subscriptions.add(
      this.dirtyService.dirty.subscribe(isDirty => {
        this.dirty = isDirty;
      }),
    );

    this.subscriptions.add(
      this.navigationService.navigationRequest$.subscribe(async (navigationOptions: NavigationRequestModel) => {
        if (typeof navigationOptions.isNext === 'boolean') {
          console.info('Log: moving the page on navigationRequest$ subject', navigationOptions);
          await this.movePage(navigationOptions);
        } else {
          console.info('Log: moveStep on navigationRequest$ subject', navigationOptions.stepIndex);
          navigationOptions.stepIndex && (await this.moveStep());
        }

        // this.loaderService.isReady();
      }),
    );

    this.subscriptions.add(
      router.events.subscribe(async (event: any) => {
        if (event.navigationTrigger === 'popstate') {
          await this.movePrev();
        }
      }),
    );
  }

  ngAfterViewChecked(): void {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  subscriptions: Subscription = new Subscription();

  editUrl: SafeResourceUrl = '';

  debugMode = false;

  public proId = '';

  public dirty = true;

  public data: any;

  public activeStep: any;

  public activeStepIndex = 0;

  public allSteps: ProStep[] = [];

  public propertiesTemplate = 'SingleChoice';

  movePaging(movement: NavigationRequestModel) {
    if (movement.isNext) {
      console.info('Log: called moveNext from movePaging');
      return this.moveNext(movement);
    } else {
      return this.movePrev();
    }
  }

  public movePage(movement: NavigationRequestModel) {
    if (movement.isNext) {
      console.info('Log: called moveNext from movePage');
      return this.moveNext(movement);
    } else {
      return this.movePrev();
    }
  }

  private moveStep() {
    console.info('Log: called moveNext from moveStep');
    return this.moveNext({});
  }

  private async moveNext(navigationRequest: NavigationRequestModel): Promise<any> {
    this.loaderService.notReady();
    const stepPayload = {
      result: this.resultsService.activeData,
      pro: this.data,
      step: this.activeStep,
    };

    this.resultsService
      .updateProTrend(this.proId, Number(this.activeStepIndex))
      .then()
      .catch(err => {
        console.error(err);
      });

    let realIndexEnum = Number(this.activeStepIndex) + 1;
    const stepStatusExpression = `${realIndexEnum.toString()}:${this.data.steps.length}`;

    this.resultsService.dimensionQuestion[stepPayload.step.question.dimension] = stepPayload.step.question._id;

    await this.resultsService.resultHandler({ isPrivate: this.activeStep.question.isPrivate, payload: stepPayload, status: stepStatusExpression });

    if (this.activeStep?.conditions?.length) {
      console.info('Log: going for the flag conditions');
      const email = this.resultsService.getResultsForDimension('email');
      if (email) {
        const flagsResolution = await this.proRouterService.resolveFlagConditions(this.activeStep?.conditions);
        console.info('flagsResolution', flagsResolution);
      }
    }

    // now handle them flags

    const options: any = { dir: 1 };

    if (!navigationRequest.skipRules) {
      const overrideNavigation: {
        target: any;
        ops: {
          products: [];
          factors: [];
        };
      } = this.resultsService.processRules(stepPayload.step.rules, stepPayload.result, this.activeStepIndex);

      console.info('Log: overrideNavigation', overrideNavigation);

      if (stepPayload?.result?.userExistsTarget) {
        this.conditionService.conditionsChecked = false;
        await this.router.navigateByUrl(`/pro/${stepPayload.result.userExistsTarget.value}/step/0`);
        return;
      }

      if (overrideNavigation?.target) {
        realIndexEnum = overrideNavigation.target.stepIndex;
        options.enter = 1;

        if (overrideNavigation.target.foundStep?.proId) {
          await this.router.navigateByUrl(`/pro/${overrideNavigation.target.foundStep.proId}/step/${realIndexEnum}`);
          return;
        }
      }

      if (stepPayload.step.nextStepTarget) {
        const target = stepPayload.step.nextStepTarget;
        console.info('Log: nextStepTarget', target);
        if (target.type === 'question') {
          for (let stepIndex = 0; stepIndex < this.data.steps.length; stepIndex++) {
            const stepData = this.data.steps[stepIndex];
            if (stepData.questionId === target.value) {
              realIndexEnum = stepIndex;
              break;
            }
          }
        } else {
          // is pro find the first question in it

          const navigationTarget = this.proService.getFirstStepOfPro(target.value, this.activeStepIndex);
          console.info('Log: navigationTarget', navigationTarget);
          realIndexEnum = navigationTarget?.stepIndex || 0;
          console.info('Log: navigationTarget index', realIndexEnum);
        }
      } else if (!overrideNavigation?.target) {
        const nextStepByConditions = await this.proService.getNextUnSkippedStep(this.activeStepIndex + 1);
        if (nextStepByConditions) {
          realIndexEnum = nextStepByConditions.currentIndex;
        }
      }

      this.timelineService.mergeStep(Number(this.activeStepIndex), { ops: overrideNavigation.ops, to: Number(realIndexEnum), skipOnBack: false });
    }

    if (this.route.snapshot.queryParams.enter) {
      if (!this.activeStep.final) {
        options.enter = 1;
      }
    }

    if (this.route.snapshot.queryParams.debug) {
      options.debug = 1;
    }

    if (this.route.snapshot.queryParams.enter) {
      options.enter = 1;
    }

    // this.loaderService.isReady();
    if (this.data.steps.length === realIndexEnum) {
      // this.loaderService.isReady();
      //  this.router.navigateByUrl(`/pro/${this.proId}/complete`);
    } else {
      // concat the options
      const queryParams = Object.keys(options)
        .map(o => `${o}=${options[o]}`)
        .join('&');

      console.info('Log: navigateByUrl', `/pro/${this.proId}/step/${realIndexEnum}?${queryParams}`);
      return this.router.navigateByUrl(`/pro/${this.proId}/step/${realIndexEnum}?${queryParams}`);
    }
  }

  public async movePrev() {
    const prevStep = this.timelineService.movePrev(this.activeStepIndex);
    const options: any = { dir: -1 };
    if (this.route.snapshot.queryParams.debug) {
      options.debug = 1;
    }

    if (this.route.snapshot.queryParams.enter) {
      options.enter = 1;
    }

    const queryParams = Object.keys(options)
      .map(o => `${o}=${options[o]}`)
      .join('&');

    const fullUrl = `/pro/${this.proId}/step/${prevStep}?${queryParams}`;
    return this.router.navigateByUrl(fullUrl);
  }

  public getRouteAnimation(outlet: RouterOutlet) {
    if (outlet.isActivated) {
      return +outlet.activatedRoute.snapshot.queryParams.dir > 0 || !outlet.activatedRoute.snapshot.queryParams.dir ? 'next' : 'prev';
    }
    return 'next';
  }

  checkForProducts() {
    const products = this.sessionStorageService.getItem(SessionKeys.Products) || {};
    if (products) {
      if (Object.keys(products).length > 0) {
        return true;
      }
    }
    return false;
  }
}
